import Vue from 'vue'
import Vuex from 'vuex'

import usuario from './modules/usuario'
import suites from './modules/suites'
import utiles from './modules/utiles'

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    usuario,
    suites,
    utiles
  }
})