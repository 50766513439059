const usuario = {
  namespaced: true,
  state: () => ({
    usuario: null,
    tiendasUsuario: [],
    proveedoresUsuario: [],
  }),

  getters: {
    usuario(state) {
      return state.usuario;
    },
    tiendasUsuario(state) {
      return state.tiendasUsuario;
    },
    proveedoresUsuario(state) {
      return state.proveedoresUsuario;
    },
  },

  mutations: {
    mutate(state, payload) {
      state[payload.property] = payload.with;
    },
  },

  actions: {
    cambiaUsuario({ commit }, usuario) {
      commit("mutate", { property: "usuario", with: usuario });
    },

    cambiaTiendasUsuario({ commit }, tiendasUsuario) {
      commit("mutate", { property: "tiendasUsuario", with: tiendasUsuario });
    },

    cambiaProveedoresUsuario({ commit }, proveedoresUsuario) {
      commit("mutate", {
        property: "proveedoresUsuario",
        with: proveedoresUsuario,
      });
    },
  },
};

export default usuario;
