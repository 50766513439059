<template>
  <v-app>
    <v-main>
      <div class="text-center">
        <v-overlay :value="splash">
          <v-progress-circular indeterminate size="64"> </v-progress-circular>
          <div class="pt-5">Espere un momento...</div>
        </v-overlay>
      </div>

      <div v-if="!splash">
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { getFirebase } from "@/firebase/firebase"
const { firestore } = getFirebase()
import { getAuth, onAuthStateChanged, signOut } from "@firebase/auth"
import { doc, onSnapshot } from "@firebase/firestore"
import { querys } from "./querys"
const auth = getAuth()

export default {
  components: {},
  name: "app",
  data() {
    return {
      splash: true,
    }
  },
  mounted() {
    // trace(performance, "Suites App-start")

    onSnapshot(doc(firestore, "configuracion", "actualizando"), (doc) => {
      if (doc.data().procesando) {
        signOut(auth)
      }
    })

  },
  created() {

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        await querys.getUserData(user)

        // Por Default establecer la primera tienda y el primer tipo de suite del Usuario
        const tiendaSeleccionada = this.$store.getters["suites/tiendaSeleccionada"].centro
        const tipoSuiteSeleccionado = this.$store.getters["suites/tipoSuiteSeleccionado"]

        this.$store.dispatch("suites/activaListener", { tiendaSeleccionada, tipoSuiteSeleccionado })
        this.splash = false
      } else {
        // Sin Usuario
        querys.cleanUserData()
        this.splash = false
      }
    })
  },
}
</script>

<style scoped>
.fixContainer {
  max-width: 100vw;
  max-height: 90vh;
}
</style>
