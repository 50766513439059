export const config = {
  firebase: {
    apiKey: "AIzaSyD3G9BgbzUsMxPWBWgK1JMba6ZHVFTxbK0",
    authDomain: "kuroda-apps-305323.firebaseapp.com",
    projectId: "kuroda-apps-305323",
    storageBucket: "kuroda-apps-305323.appspot.com",
    messagingSenderId: "133632214897",
    appId: "1:133632214897:web:db46d2014d2af8151218d0",
    measurementId: "G-9XSW2GTVZR",
  },
};
