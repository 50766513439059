import { getFirebase } from "@/firebase/firebase";
const { firestore } = getFirebase();

import { collection, query, where, onSnapshot } from "@firebase/firestore";
import store from "@/store";

const suites = {
  namespaced: true,
  state: () => ({
    tiposSuite: null,
    tipoSuiteSeleccionado: null,
    tiendaSeleccionada: null,
    proveedorSeleccionado: null,
    proveedoresSuites: [],
    suites: null,
    suiteSeleccionada: null,
    totales: null,
    listener: null,
  }),

  getters: {
    tiposSuite(state) {
      return state.tiposSuite;
    },
    tipoSuiteSeleccionado(state) {
      return state.tipoSuiteSeleccionado;
    },
    tiendaSeleccionada(state) {
      return state.tiendaSeleccionada;
    },
    proveedorSeleccionado(state) {
      return state.proveedorSeleccionado;
    },
    proveedoresSuites(state) {
      return state.proveedoresSuites;
    },
    suites(state) {
      return state.suites;
    },
    suiteSeleccionada(state) {
      return state.suiteSeleccionada;
    },

    totales(state) {
      return state.totales;
    },
  },
  mutations: {
    mutate(state, payload) {
      state[payload.property] = payload.with;
    },
  },
  actions: {
    activaListener({ commit }, { tiendaSeleccionada, tipoSuiteSeleccionado }) {
      // const t = trace(performance, "Escuchando suites");

      const proveedoresUsuario = store.getters["usuario/proveedoresUsuario"];

      commit("mutate", { property: "listener", with: null });

      const colRef = collection(firestore, "suites");
      const q = query(
        colRef,
        where("centro", "==", tiendaSeleccionada),
        where("tipoSuite", "==", tipoSuiteSeleccionado)
      );

      const unsub = onSnapshot(q, (snapshot) => {
        // t.start();

        let suites = [];
        let proveedores = [];
        let suitesConExistencia = 0;
        let suitesSinExistencia = 0;
        let materialesConExistencia = 0;
        let materialesSinExistencia = 0;
        let totalSuites = 0;
        let totalMateriales = 0;

        snapshot.docs.forEach((doc) => {
          // filtrar  proveedor que no se tengan derechos de acceso
          if (
            proveedoresUsuario.includes("Todos") ||
            proveedoresUsuario.includes(doc.data().proveedor)
          ) {
            materialesConExistencia =
              materialesConExistencia + doc.data().materialesConExistencia;
            materialesSinExistencia =
              materialesSinExistencia + doc.data().materialesSinExistencia;

            if (doc.data().materialesSinExistencia == 0) {
              suitesConExistencia++;
            } else {
              suitesSinExistencia++;
            }
            suites.push({ ...doc.data(), id: doc.id });
            proveedores.push(doc.data().proveedor);
          }
        });

        if (proveedores.length > 1) {
          proveedores.unshift("Todos");
        }

        totalSuites = suitesConExistencia + suitesSinExistencia;
        totalMateriales = materialesConExistencia + materialesSinExistencia;

        const datosSuites = {
          formato: "Suites",
          conExistencia: suitesConExistencia,
          sinExistencia: suitesSinExistencia,
          totales: totalSuites,
        };

        const datosMateriales = {
          formato: "Materiales",
          conExistencia: materialesConExistencia,
          sinExistencia: materialesSinExistencia,
          totales: totalMateriales,
        };

        const proveedoresSuites = Array.from(new Set(proveedores));

        store.dispatch("suites/cambiaSuites", suites);
        store.dispatch("suites/cambiaTotales", {
          suites: datosSuites,
          materiales: datosMateriales,
        });

        store.dispatch("suites/cambiaProveedoresSuites", proveedoresSuites);

        if (proveedoresSuites.length == 1) {
          store.dispatch(
            "suites/cambiaProveedorSeleccionado",
            proveedoresSuites[0]
          );
        } else {
          store.dispatch("suites/cambiaProveedorSeleccionado", "Todos");
        }

        commit("mutate", { property: "listener", with: unsub });
        commit("mutate", { property: "suites", with: suites });
        // t.stop();
      });
    },
    cambiaTiposSuite({ commit }, tiposSuite) {
      commit("mutate", { property: "tiposSuite", with: tiposSuite });
    },

    cambiaTipoSuiteSeleccionado({ commit }, tipoSuiteSeleccionado) {
      commit("mutate", {
        property: "tipoSuiteSeleccionado",
        with: tipoSuiteSeleccionado,
      });
    },
    async cambiaTiendaSeleccionada({ commit }, tiendaSeleccionada) {
      try {
        await commit("mutate", {
          property: "tiendaSeleccionada",
          with: tiendaSeleccionada,
        });
      } catch (error) {
        console.error(error);
      }
    },
    cambiaProveedorSeleccionado({ commit }, proveedorSeleccionado) {
      commit("mutate", {
        property: "proveedorSeleccionado",
        with: proveedorSeleccionado,
      });
    },
    cambiaProveedoresSuites({ commit }, proveedoresSuites) {
      commit("mutate", {
        property: "proveedoresSuites",
        with: proveedoresSuites,
      });
    },
    cambiaSuites({ commit }, suites) {
      commit("mutate", { property: "suites", with: suites });
    },

    async cambiaSuiteSeleccionada({ commit }, suiteSeleccionada) {
      try {
        await commit("mutate", {
          property: "suiteSeleccionada",
          with: suiteSeleccionada,
        });
      } catch (error) {
        console.error(error);
      }
    },
    cambiaTotales({ commit }, totales) {
      commit("mutate", { property: "totales", with: totales });
    },
  },
};

export default suites;
