import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "animate.css";

import VueHtmlToPaper from "vue-html-to-paper";
const options = {
  specs: ["fullscreen=yes", "titlebar=no", "scrollbars=no"],
};
Vue.use(VueHtmlToPaper, options);

import store from "./store";
import { getAuth, onAuthStateChanged } from "@firebase/auth";

Vue.config.productionTip = false;
Vue.config.devtools = true;

const auth = getAuth();
const unsubscribe = onAuthStateChanged(auth, () => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
  unsubscribe();
});
