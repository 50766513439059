const utiles = {
  namespaced: true,
  state: () => ({
    waiting: false,
  }),

  getters: {
    waiting(state) {
      return state.waiting;
    },
  },

  mutations: {
    mutate(state, payload) {
      state[payload.property] = payload.with;
    },
  },

  actions: {
    async waiting({ commit }, waiting) {
      commit("mutate", { property: "waiting", with: waiting });
    },
  },
};

export default utiles;
