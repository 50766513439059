import Vue from "vue";
import VueRouter from "vue-router";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
const auth = getAuth();

const Home = (resolve) => require(["../views/Home.vue"], resolve);
const Login = (resolve) => require(["../views/Login.vue"], resolve);
const Actualizando = (resolve) =>
  require(["../views/Actualizando.vue"], resolve);

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // beforeEnter: NeedtoBeAuthenticated, // Solo si ha hecho login Entra aqui
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      NeedstoNotBeAuthenticated: true,
    },
  },
  {
    path: "/suites",
    name: "Suites",
    component: () =>
      import(/* webpackChunkName: "Suites" */ "../views/Suites.vue"),
    // beforeEnter: NeedtoBeAuthenticated,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contenido/:id",
    name: "Contenido",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Contenido" */ "../views/Contenido.vue"),
    meta: {
      requiresAuth: true,
    },
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
  },
  {
    path: "/config",
    name: "Config",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Config" */ "../views/Config.vue"),
    meta: {
      requiresAuth: true,
    },
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
  },
  {
    path: "/config/:id",
    name: "ConfigSuite",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Config" */ "../views/ConfigSuite.vue"),
    meta: {
      requiresAuth: true,
    },
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
  },
  {
    path: "/info/:id",
    name: "Info",
    props: true,
    component: () =>
      import(/* webpackChunkName: "SuiteQr" */ "../views/DigitalDisplay.vue"),
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
  },
  {
    path: "/actualizando",
    name: "Actualizando",
    component: Actualizando,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "../views/Dashboard.vue"),
    // beforeEnter: NeedtoBeAuthenticated,
    meta: {
      requiresAuth: true,
    },
  },
  { path: "*", redirect: { name: "Home" } },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const NeedstoNotBeAuthenticated = to.matched.some(
    (x) => x.meta.NeedstoNotBeAuthenticated
  );

  onAuthStateChanged(auth, (user) => {
    if (NeedstoNotBeAuthenticated && user) {
      next("/");
    } else if (requiresAuth && !user) {
      next("/login");
    } else {
      next();
    }
  });
});

export default router;
