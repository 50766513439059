"use strinct";

// asi se manda llamar los querys desde el componente

// import { querys } from "../querys";
// querys.getData()
import { getFirebase } from "@/firebase/firebase";
const { firestore, secondaryApp } = getFirebase();

import {
  collection,
  query,
  where,
  getDoc,
  getDocs,
  doc,
  setDoc,
  writeBatch,
} from "@firebase/firestore";

import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  updateProfile,
} from "@firebase/auth";

import store from "./store";

const userF = {
  async createUser(displayName, email, password, tiendas, proveedores) {
    try {
      // authForCreateUser Prevents to Automatic Authenticate and Sign In
      const authForCreateUser = getAuth(secondaryApp);
      const newUser = await createUserWithEmailAndPassword(
        authForCreateUser,
        email,
        password
      );

      const derechosRef = doc(firestore, "derechos", email);
      await setDoc(derechosRef, { proveedores, tiendas }, { merge: false });

      await updateProfile(newUser, { displayName });
      return signOut(authForCreateUser);

    } catch (error) {
      console.error(error);
      return false;
    }
  },

  signOut() {
    const auth = getAuth();
    return signOut(auth).then(
      store.dispatch("usuario/cambiaUsuario", { usuario: null })
    );
  },

  async signIn(email, password) {
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      store.dispatch("usuario/cambiaUsuario", {
        displayName: user.displayName,
        email: user.email,
      });
    } catch (error) {
      return error;
    }
  },
};

const querysF = {
  async eliminaSuite(suiteID) {
    const encabezadoRef = doc(firestore, "suites", suiteID);
    const colDetalle = collection(firestore, "detalle");
    const q = query(colDetalle, where("suite", "==", suiteID));
    const querySnapshot = await getDocs(q);

    let referenciasDetalle = [];
    querySnapshot.forEach((detalle) => {
      referenciasDetalle.push(detalle.ref);
    });

    const batch = writeBatch(firestore);

    batch.delete(encabezadoRef);
    for (const detalleRef of referenciasDetalle) {
      batch.delete(detalleRef);
    }
    await batch.commit();
  },
  async insertaNuevaSuite(encabezado, detalle) {
    const suitesRef = doc(firestore, "suites", encabezado.id);
    let promises = [setDoc(suitesRef, encabezado, { merge: false })];
    for (const renglon of detalle) {
      const detalleRef = doc(
        firestore,
        "detalle",
        encabezado.id + "-" + renglon.orden
      );
      promises.push(setDoc(detalleRef, renglon, { merge: false }));
    }
    await Promise.all(promises);
  },

  async insertaNuevoProveedor(nombre) {
    const proveedorRef = doc(firestore, "proveedores", nombre);
    return setDoc(proveedorRef, { nombre: nombre }, { merge: false });
  },

  async insertaNuevaTienda(centro, nombre) {
    const tiendaRef = doc(firestore, "tiendas", centro);
    return setDoc(tiendaRef, { nombre: nombre }, { merge: false });
  },

  async getTiendas() {
    const docsSnap = await getDocs(collection(firestore, "tiendas"))
    let tiendas = [];
    docsSnap.forEach(tienda => {
      tiendas.push({ centro: tienda.id, tienda: tienda.data().nombre });
  })
    return tiendas;
  },

  async getProveedores() {
    const querySnapshot = await getDocs(collection(firestore, "proveedores"));
    let proveedores = [];

    for (const proveedor of querySnapshot.docs) {
      proveedores.push(proveedor.data().nombre);
    }

    return proveedores;
  },

  async getTiposSuite() {
    const docRef = doc(firestore, "configuracion", "tiposSuite");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists) {
      const tiposSuite = docSnap.data().tipos;
      store.dispatch("suites/cambiaTiposSuite", tiposSuite);
      store.dispatch("suites/cambiaTipoSuiteSeleccionado", tiposSuite[0]);
    }
  },

  async getUserRights(user) {
    const docRef = doc(firestore, "derechos", user.email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists ) {
 
      const derechos = docSnap.data();
      const usuario = { displayName: user.displayName, email: user.email };
      const tiendasUsuario = derechos?.tiendas;
      const proveedoresUsuario = derechos?.proveedores;

      store.dispatch("usuario/cambiaUsuario", usuario);
      store.dispatch("usuario/cambiaTiendasUsuario", tiendasUsuario);
      store.dispatch("suites/cambiaTiendaSeleccionada", tiendasUsuario[0]);
      store.dispatch("usuario/cambiaProveedoresUsuario", proveedoresUsuario);
      store.dispatch(
        "suites/cambiaProveedorSeleccionado",
        proveedoresUsuario[0]
      );
    }
  },

  async getSuiteID(suiteID) {
    const suiteRef = doc(firestore, "suites", suiteID);
    const docSnap = await getDoc(suiteRef);

    if (docSnap.exists) {
      const suiteSeleccionada = { id: docSnap.id, ...docSnap.data() };
      store.dispatch("suites/cambiaSuiteSeleccionada", suiteSeleccionada);
      return true;
    } else {
      return false;
    }
  },
  async getSuiteDetalle(suiteID) {
    const suitesRef = query(
      collection(firestore, "detalle"),
      where("suite", "==", suiteID)
    );
    const suites = await getDocs(suitesRef);
    let detalle = [];
    for (const doc of suites.docs) {
      detalle.push({ id: doc.id, ...doc.data() });
    }
    return detalle;
  },
  cleanUserData() {
    store.dispatch("usuario/cambiaUsuario", null);
    store.dispatch("usuario/cambiaTiendasUsuario", null);
    store.dispatch("suites/cambiaTiendaSeleccionada", null);
    store.dispatch("usuario/cambiaProveedoresUsuario", null);
    store.dispatch("suites/cambiaProveedorSeleccionado", null);
    store.dispatch("suites/cambiaTiposSuite", null);
    store.dispatch("suites/cambiaTipoSuiteSeleccionado", null);
  },

  async getUserData(user) {
    // const t = trace(performance, "getUserData");
    // t.start();

    try {
      await store.dispatch("utiles/waiting", true);
      await Promise.all([this.getTiposSuite(), this.getUserRights(user)]);
      await store.dispatch("utiles/waiting", false);
    } catch (error) {
      console.error(error);
    } finally {
      // t.stop();
    }
  },
};

const suiteOpsF = {
  insertaNewSuiteHeaderConID(docId, contenido) {
    const suiteRef = doc(firestore, "suites", docId);
    return setDoc(suiteRef, contenido, { merge: false });
  },
  insertaNewSuiteDetalleConID(docDid, contenido) {
    const suiteDRef = doc(firestore, "detalle", docDid);
    return setDoc(suiteDRef, contenido, { merge: false });
  },
};

export const querys = querysF;
export const user = userF;
export const suiteOps = suiteOpsF;
