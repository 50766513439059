import { initializeApp, getApps } from "@firebase/app";
import { getFirestore, connectFirestoreEmulator } from "@firebase/firestore";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  connectAuthEmulator,
} from "@firebase/auth";

import { getFunctions, connectFunctionsEmulator } from "@firebase/functions";
// import { getPerformance, trace } from "firebase/performance";
import { config } from "./config";

function initializeServices() {
  const firebaseApp = initializeApp(config.firebase);
  const firestore = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  const functions = getFunctions(firebaseApp);
  // const performance = getPerformance(firebaseApp);

  auth.useDeviceLanguage();
  setPersistence(auth, browserLocalPersistence);

  const secondaryApp = initializeApp(config.firebase, "Secondary");

  const isConfigured = getApps().length > 0;

  return {
    firebaseApp,
    firestore,
    auth,
    isConfigured,
    functions,
    // performance,
    // trace,
    secondaryApp,
  };
}

function connectToEmulators({ auth, firestore, functions }) {
  if (location.hostname === "localhostx") {
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(firestore, "localhost", 8081);
    connectFunctionsEmulator(functions, "localhost", 5001);
  }
}

export function getFirebase() {
  const services = initializeServices();
  if (!services.isConfigured) {
    connectToEmulators(services);
  }
  return services;
}
